import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Button } from './styles'
import { devices } from '../lib/constants'

const A = css`
  color: inherit;
  text-decoration: none;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-dark);
  margin: 0 var(--spacer-small);
  border-radius: var(--border-radius-small);
  line-height: 1;
  transition: 0.3s;

  @media ${devices.tablet} {
    margin: 0 calc(var(--spacer-small) / 2);
  }

  &:hover {
    color: var(--color-light);
    border-color: var(--color-primary);
    background: var(--color-primary);
  }
`

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--spacer-large) 0;
`

const StyledPaginationItem = styled(Link)`
  ${A}

  &:hover,
  &.current-pagenum {
    color: var(--color-light);
    border-color: var(--color-primary);
    background: var(--color-primary);
  }
`

const StyledPaginationPrev = styled(Link)`
  ${A}
  margin-right: var(spacer-small);
`

const StyledPaginationNext = styled(Link)`
  ${A}
  margin-left: var(spacer-small);
`

const Pagination = ({ pageContext: { uid, currentPage, numPages }, label, linkPrefix = '' }) => {
  if (numPages <= 1) {
    return null
  }

  const prevLink = currentPage <= 2 ? `${linkPrefix}/${uid}` : `${linkPrefix}/${uid}/${currentPage - 1}`
  const nextLink =
    currentPage === numPages
      ? `${linkPrefix}/${uid}/${currentPage}`
      : `${linkPrefix}/${uid}/${currentPage + 1}`

  if (currentPage === 1) {
    return (
      <StyledPagination>
        <Button as={Link} to={nextLink} rel="next" aria-label="Volgende pagina">
          {label}
        </Button>
      </StyledPagination>
    )
  }

  return (
    <StyledPagination>
      <StyledPaginationPrev
        to={prevLink}
        rel="prev"
        aria-label="Vorige pagina"
        aria-disabled={currentPage <= 1}
        activeClassName="disable"
      >
        ←
      </StyledPaginationPrev>

      {Array.from({ length: numPages }, (_, i) => (
        <StyledPaginationItem
          key={`pagenum-${i + 1}`}
          to={i === 0 ? `${linkPrefix}/${uid}` : `${linkPrefix}/${uid}/${i + 1}`}
          activeClassName="current-pagenum"
        >
          {i + 1}
        </StyledPaginationItem>
      ))}

      <StyledPaginationNext
        to={nextLink}
        rel="next"
        aria-label="Volgende pagina"
        aria-disabled={currentPage === numPages}
        activeClassName="disable"
      >
        →
      </StyledPaginationNext>
    </StyledPagination>
  )
}

export default Pagination
