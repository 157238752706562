import React from 'react'

const Categories = ({ categories }) => {
  if (!categories) {
    return null
  }

  return <span>{categories[0].category.document.data.titel}</span>

  // return categories.map(({ category }) => (
  //   <span key={category.document.id}>{category.document.data.titel}</span>
  // ))
}

export default Categories
