import React from 'react'
import { Link } from 'gatsby'

import ArticleHeader from './article-header'
import ArticleImage from './article-image'
import { StyledCard } from './styles'

const CardArticle = ({ node }) => (
  <StyledCard variant="column">
    <Link to={`/${process.env.GATSBY_PRISMIC_ARTICLE_PAGE}/${node.uid}`}>
      <ArticleImage
        image={node.data.featured_image?.thumbnails.Thumbnail}
        alt={
          node.data.featured_image.thumbnails.Thumbnail.alt ||
          node.data.featured_image.alt ||
          node.data.title.text
        }
        aspectRatio={{ width: 1, height: 0.74635 }}
      />
      <ArticleHeader categories={node.data.categories} tags={node.tags}>
        <h3>{node.data.title.text}</h3>
      </ArticleHeader>

      <p>{node.data.excerpt}</p>
    </Link>
  </StyledCard>
)

export default CardArticle
