import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import CardArticle from '../components/card-article'
import CategoriesMenu from '../components/categories-menu'
import Pagination from '../components/pagination'
import Seo from '../components/seo'
import PinnedArticles from '../components/pinned-articles'
import { StyledGrid } from '../components/styles'

const ArticleOverviewTemplate = ({ pageContext, data }) => {
  const page = data.prismicPage
  const articles = data.allPrismicArticle
  const seoTitle =
    pageContext.currentPage > 1
      ? `${page.data.title.text} · Pagina ${pageContext.currentPage}`
      : page.data.title.text

  return (
    <Layout>
      <Seo title={seoTitle} />

      <h1 className="font-gradient-blue">{page.data.title.text}</h1>
      <CategoriesMenu />

      <StyledGrid columns="3">
        {articles.edges.slice(0, 3).map(({ node }) => (
          <CardArticle key={node.uid} node={node} />
        ))}
      </StyledGrid>

      <PinnedArticles title="Uitgelichte artikelen" />

      <StyledGrid columns="3">
        {articles.edges.slice(3).map(({ node }) => (
          <CardArticle key={node.uid} node={node} />
        ))}
      </StyledGrid>

      <Pagination pageContext={pageContext} label="Meer publicaties" />
    </Layout>
  )
}

export default ArticleOverviewTemplate

export const query = graphql`
  query ArticleOverviewQuery($uid: String!, $limit: Int!, $skip: Int!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
      }
    }

    allPrismicArticle(
      sort: { fields: data___publication_date, order: DESC }
      filter: { type: { eq: "article" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            ...cardOverviewDataFragment
          }
          tags
        }
      }
    }
  }
`
