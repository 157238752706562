import React from 'react'
import { Link } from 'gatsby'

import ArticleHeader from './article-header'
import ArticleImage from './article-image'
import { StyledCardPinned } from './styles'

const CardArticlePinned = ({ node }) => (
  <StyledCardPinned key={node.uid}>
    <Link to={`/${process.env.GATSBY_PRISMIC_ARTICLE_PAGE}/${node.uid}`}>
      <ArticleHeader categories={node.data.categories} tags={node.tags}>
        <h3>{node.data.title.text}</h3>
      </ArticleHeader>

      <ArticleImage
        image={node.data.featured_image?.thumbnails.Uitgelicht}
        alt={
          node.data.featured_image.thumbnails.Uitgelicht.alt ||
          node.data.featured_image.alt ||
          node.data.title.text
        }
        aspectRatio={{ width: 1, height: 0.74635 }}
      />
    </Link>
  </StyledCardPinned>
)

export default CardArticlePinned
