import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CardArticlePinned from './card-article-pinned'
import { StyledFullWidthSection, StyledMain, StyledScrollableRow } from './styles'

const PinnedArticles = ({ title }) => {
  const { allPrismicArticle } = useStaticQuery(query)

  if (allPrismicArticle.totalCount === 0) {
    return null
  }

  return (
    <StyledFullWidthSection bgColor="--color-dark" color="--color-light" bgImage="0.05">
      <StyledMain as="div">
        <h2 className="font-gradient-grey">{title}</h2>
        <StyledScrollableRow columns="2" autoFlow="column" count={allPrismicArticle.totalCount}>
          {allPrismicArticle.edges.map(({ node }) => (
            <CardArticlePinned key={node.uid} node={node} />
          ))}
        </StyledScrollableRow>
      </StyledMain>
    </StyledFullWidthSection>
  )
}

export default PinnedArticles

const query = graphql`
  query PinnedArticlesQuery {
    allPrismicArticle(
      sort: { fields: data___publication_date, order: DESC }
      filter: { data: { pinned: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          data {
            ...cardOverviewDataFragment
          }
          tags
          uid
        }
      }
    }
  }
`
