import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import linkResolver from '../lib/link-resolver'
import { StyledNavHorizontal } from './styles'

const CategoriesMenu = () => {
  const { allPrismicCat, allPrismicPage } = useStaticQuery(query)
  const articlePage = allPrismicPage.edges[0].node.uid

  return (
    <StyledNavHorizontal scroll="true">
      <div>
        {articlePage && (
          <Link to={`/${articlePage}`} activeClassName="current-cat">
            Alles
          </Link>
        )}

        {allPrismicCat.nodes?.map((item) => {
          const link = linkResolver(item)

          return (
            <Link key={item.uid} to={link} activeClassName="current-cat">
              {item.data.titel}
            </Link>
          )
        })}
      </div>
    </StyledNavHorizontal>
  )
}

export default CategoriesMenu

const query = graphql`
  query CategoriesMenuQuery {
    allPrismicPage(filter: { data: { article_overview: { eq: true } } }) {
      edges {
        node {
          uid
        }
      }
    }

    allPrismicCat {
      nodes {
        data {
          titel
        }
        uid
        type
      }
    }
  }
`
