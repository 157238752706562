import React from 'react'

import Categories from './categories'
import { StyledArticleMeta } from './styles'

const ArticleHeader = ({ categories, date, tags, children }) => (
  <header>
    <StyledArticleMeta>
      {date && <time dateTime={date}>{date}</time>}
      {categories && <Categories categories={categories} />}
      {(date || categories) && <span className="separator"></span>}
      {tags && <span>{tags.join(', ')}</span>}
    </StyledArticleMeta>
    {children}
  </header>
)

export default ArticleHeader
